div.field-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div.map-container {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }

  div.particles {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid $dy-grey-bg;

    button {
      height: 38px;
    }

    div.col-12:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      button {
        width: fit-content;
        height: 38px;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      div.particle-form {
        div.col-12:last-child {
          padding-top: 1.8rem;
        }
      }
    }
  }
}

div.active-culture {
  div.warning {
    background-color: #fff;
    border: 1px solid $dy-grey-bg;
    border-radius: 6px;
    padding: 0.5rem;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-template-areas: "icon content";
    gap: 0.5rem;

    .icon {
      grid-area: icon;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 45px;
      width: 45px;
      border: 1.5px solid #f29508;
      border-radius: 6px;

      i {
        height: min-content;
        font-size: 1rem;
        font-weight: bold;
        color: #f29508;
      }
    }

    .content {
      grid-area: content;

      p:last-child {
        color: $dy-grey-text;
      }

      p:first-child {
        font-size: 1rem;
      }
    }
  }

  div.info {
    width: 100%;
    overflow: hidden;
    display: grid;
    gap: 1rem;
    grid-template-columns: 100%;

    div.actions {
      display: flex;
      justify-content: center;
      gap: 1rem;

      & > button {
        font-size: 13px;
      }

      .btn-outline-success {
        --bs-btn-disabled-color: #{$dy-grey-text} !important;
        --bs-btn-disabled-border-color: #{$dy-grey-text} !important;
      }

      .btn:disabled,
      :not(.btn-check) + .btn:active {
        color: var(--bs-btn-disabled-color);
        background-color: var(--bs-btn-disabled-bg);
        border-color: var(--bs-btn-disabled-border-color);
      }
    }

    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
      grid-template-columns: repeat(2, 20ch) repeat(2, 1fr);
      grid-template-areas:
        "culture variety start end"
        "actions actions actions actions";
      column-gap: 2rem;
      row-gap: 2rem;

      div.item:last-child {
        grid-area: actions;
      }
    }

    @media screen and (min-width: calc(map-get($grid-breakpoints, xl) + 0.02px)) {
      grid-template-columns: repeat(2, minmax(18ch, 22ch)) repeat(3, 1fr);
      column-gap: 2rem;

      div.item:last-child {
        align-self: center;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, xxl)) {
      grid-template-columns: repeat(2, minmax(18ch, 30ch)) repeat(3, 1fr);
      column-gap: 4rem;
    }
  }
}

div.field-culture {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  div.item {
    padding: 0.5rem 0;
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }

  div.item:last-child {
    border-bottom: none;
  }
}

.accordion {
  --bs-accordion-bg: #{$dy-grey-light};
  --bs-accordion-border-color: #{$dy-grey-bg};
  --bs-accordion-border-radius: 6px;

  &-item {
    &:not(:first-of-type) {
      border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    }

    border-radius: var(--bs-accordion-inner-border-radius) !important;

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  &-header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);

    color: var(--bs-accordion-btn-color);
    background-color: var(--bs-accordion-btn-bg);
    text-align: left;
    font-size: 14px;
    overflow-anchor: none;
    border: 0;
    transition: var(--bs-accordion-transition);

    min-height: 80px;

    i {
      padding-left: var(--bs-accordion-btn-padding-x);
      font-size: 1rem;
      color: $dy-blue;
    }

    &:focus {
      box-shadow: none;
    }

    &::after {
      transition: none;
    }

    &:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      i {
        padding-left: calc(var(--bs-accordion-btn-padding-x));
      }

      align-items: center;

      div.field-culture {
        display: grid;

        div.item {
          padding: 0;
          border-bottom: none;

          &:first-child {
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        div.item {
          padding: 0;
          border-bottom: none;
        }
      }
    }

    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
      div.field-culture {
        grid-template-columns: 20ch 150px max-content;
        column-gap: 1rem;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, xl)) {
      div.field-culture {
        grid-template-columns: 30ch 150px max-content;
        column-gap: 4rem;
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, xxl)) {
      i {
        padding: 0 calc(var(--bs-accordion-btn-padding-x));
      }

      div.field-culture {
        grid-template-columns: repeat(3, calc(100% / 3));
        column-gap: 2rem;

        div.item:first-child p {
          width: 30ch;
        }
      }
    }
  }
}

.timeline {
  list-style: none;
  margin: 0;
  position: relative;
  padding: 0;

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 1rem;
  }

  .timeline-item {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
      flex-wrap: nowrap;
      position: relative;

      .timeline-line {
        flex-shrink: 0;
        background-color: #838383;
        width: 1px;
      }

      .timeline-bullet {
        --bsb-tl-color: #{$dy-blue};
        --bsb-tl-circle-color: var(--bs-white);
        --bsb-tl-circle-border-color: var(--bs-primary);
        --bsb-tl-circle-shadow-color: var(--bs-primary-rgb);
        --bsb-tl-circle-size: 20px;
        --bsb-tl-circle-offset: -9px;
        --bsb-tl-circle-border-size: 2px;

        position: absolute;
        top: 0;
        left: var(--bsb-tl-circle-offset);
        z-index: 1;

        width: var(--bsb-tl-circle-size);
        height: var(--bsb-tl-circle-size);
        border-radius: 50%;
        margin-top: calc((82px - 20px) / 2);
      }

      .timeline-label {
        flex-shrink: 0;
        margin-left: 0;
        margin-top: calc((82px - 21px) / 2);
        text-align: center;
        width: 180px;
      }

      .timeline-content {
        width: calc(calc(100% - 180px));
        margin: 0;
        padding: 0;
        position: relative;
        padding-bottom: 1rem;
      }

      &:only-child {
        .timeline-line {
          width: 0;
        }
      }

      &:first-of-type {
        .timeline-line {
          margin-top: 41px;
        }
      }

      &:last-child {
        .timeline-content {
          padding-bottom: 0;
        }

        .timeline-line {
          height: 41px;
        }
      }
    }
  }
}

div.items-header {
  width: 100%;
  height: 46px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    height: 46px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .btn.rounded-circle {
    width: 45px;
    height: 45px !important;
    background-color: $dy-grey-primary;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    font-size: 20px;
    color: $dy-grey-text;

    &:hover {
      background: $dy-grey-bg;
    }
  }

  div.controls {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    min-width: 484px;
    max-width: 500px;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    padding: 0.75rem;
    padding-bottom: 0.2rem;
  }
}

div.field-device-data {
  display: flex;
  flex-direction: column;
  height: 100%;

  div.device-select-container {
    width: 100%;

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      max-width: 160px !important;
    }

    @media screen and (min-width: 1201px) and (max-width: 1400px) {
      width: 50%;
    }

    @media screen and (min-width: 1401px) {
      width: 40%;
    }

    #device-select {
      min-width: 0 !important;
    }
  }

  div#device-select {
    .css-15dd201-control,
    .css-1v4hdkw-control,
    .css-nx2irm-MenuPortal {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }

  div.ts-box {
    height: 100px;

    div.data {
      display: grid;
      grid-template-columns: auto;
      grid-auto-flow: row;
      grid-template-areas:
        "variable"
        "value";
      grid-template-rows: auto auto;
      color: $dy-blue;

      .variable {
        grid-area: variable;
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.075rem;
      }

      .value {
        grid-area: value;
        color: $dy-grey-text;
        align-self: center;
        margin: 0;
      }
    }
  }

  div.ts-row {
    display: flex;
    gap: 1rem;

    div.ts-box {
      width: 225px;
      height: 80px;
      max-height: 100px;
      border-radius: 6px;
      background-color: $dy-grey-light;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }

    div.data {
      height: 100%;
      grid-template-rows: auto auto;
      padding: 10px;
      gap: 0px 5px;
    }

    .value {
      align-self: end;
    }
  }

  div.ts-col-header {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    height: 60px;
    border-bottom: 2px solid $dy-grey-bg;
  }

  div.ts-col-content {
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    div.ts-box {
      border-top: 1px solid $dy-grey-bg;
      &:first-child {
        border-top: none;
        border-bottom: 1px solid $dy-grey-bg;
      }

      div.data {
        padding: 1rem;
        gap: 0px 0.5rem;
        height: 100%;

        .variable {
          align-self: center;
          max-width: 340px;
          font-size: 15px;

          @media screen and (min-width: 680px) {
            max-width: 600px;
          }

          @media screen and (min-width: 930px) {
            max-width: 900px;
          }
        }

        .icon {
          grid-area: icon;
          margin-top: -4px;
          align-self: center;
          font-size: 15.5px;
          text-align: center;
        }

        .value {
          grid-area: value;
        }
      }
    }

    div.ts-box:nth-child(2) {
      border-top: none;
    }
  }

  div.show-data-container {
    margin-top: auto;
    padding: 0.8rem 1.4rem 1rem;

    @media screen and (min-width: 1025px) {
      padding: 0;
    }
  }

  @media screen and (min-width: 1025px) {
    gap: 0.5rem;
  }
}

div.field-cultures {
  display: flex;
  flex-direction: column;
  height: 100%;

  div.cultures-row {
    display: flex;
    gap: 1rem;

    div.culture-box.card {
      width: 500px;
      height: 162px;
      background: $dy-grey-light;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

      .card-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        height: 45px;
        padding: 0.5rem;
        border-bottom: 1px solid $dy-grey-bg;

        div:not(div.dropstart) {
          max-width: 465px;
        }

        div.dropstart {
          margin-left: auto;
        }

        i.icon-icon-agea-cultures-blue {
          color: $dy-green;
        }

        i.bi-plus-circle {
          color: $dy-grey-text;
          font-weight: 500;
        }

        .card-title {
          color: $dy-blue;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .card-body {
        height: calc(162px - 45px);
        padding: 12px;
        display: flex;
        align-items: center;

        color: $dy-blue;

        div.data {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 0.3rem;

          div.text-content {
            display: flex;
            flex-direction: row;
            gap: 0.3rem;
          }

          label {
            color: $dy-grey-text !important;
          }
        }
      }
    }

    div.culture-box.card.opaque {
      opacity: 90%;
    }
  }

  div.cultures-col {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;

    div.culture-box {
      height: 130px;

      &:first-child {
        border-bottom: 1px solid $dy-grey-bg;
      }

      div.data {
        display: grid;
        grid-template-columns: 30px auto;
        grid-template-rows: auto auto;
        grid-auto-flow: row;
        grid-template-areas:
          "icon culture"
          "empty dates";
        padding: 0.75rem;
        gap: 0 0.25rem;
        height: 100%;

        .culture {
          grid-area: culture;

          display: grid;
          grid-template-columns: auto, 25px;
          grid-template-rows: auto;
          grid-template-areas: ". actions";

          p {
            align-self: center;
            margin: 0;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.075rem;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $dy-blue;

            max-width: 325px;

            @media screen and (min-width: 680px) {
              max-width: 600px;
            }

            @media screen and (min-width: 930px) {
              max-width: 900px;
            }
          }

          div.actions {
            grid-area: actions;
            margin-left: auto;
            align-self: center;
          }
        }

        .icon {
          grid-area: icon;
          align-self: center;
          color: $dy-green;
          text-align: center;
          font-size: 20px;
        }

        .dates {
          grid-area: dates;
          margin: 0;
          font-size: 0.9rem;
          color: $dy-blue;
          align-self: center;

          label.text-muted {
            color: $dy-grey-text;
          }

          div.text-content {
            display: flex;
            gap: 0.25rem;
          }
        }
      }
    }

    div.culture-box:nth-last-child(2) ~ div.culture-box {
      &:first-child {
        border-bottom: none;
      }

      border-top: 1px solid $dy-grey-bg;
    }
  }

  div.items-footer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 370px;

    @media screen and (max-width: 1024px) {
      width: auto;
      margin-top: auto;
      padding: 0.8rem 1.4rem 1rem;
    }
  }

  @media screen and (min-width: 1025px) {
    gap: 0.5rem;
  }
}

div.card.empty {
  width: 100%;
  height: 103px;
  border-radius: 6px;
  opacity: 0.9;

  & > * {
    opacity: 1;
  }

  @media screen and (max-width: 1024px) {
    background: transparent;
  }
}

.btn.show-data,
.btn.add-culture,
.btn.export-culture {
  font-weight: 600;
  text-transform: uppercase;
}
