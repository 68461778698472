$min-sidebar-width: 400px;
$map-container-width: calc(100% - #{$min-sidebar-width});

div.wrapper.dashboard,
div.wrapper.field {
  position: relative;
  width: 100%;
  height: calc(100dvh - #{$topbar-height});
  padding: 0;
  margin: 0;
  background-color: $dy-grey-primary;

  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: row;
  }

  div.hr {
    border-bottom: 1px solid $dy-grey-bg;
  }

  div.map-container,
  div.map {
    z-index: 1;

    &:focus-visible {
      outline: none !important;
    }

    @media screen and (min-width: 1025px) {
      width: $map-container-width;
    }
  }

  div.field-header-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $dy-grey-bg;
    padding: 1.2rem;
    height: 60px;

    h5 {
      font-size: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    button.info {
      margin-left: auto;
      i {
        font-size: 20px;
        color: $dy-grey-text;
      }
    }
  }

  div.map-container {
    .map-go-back {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 2;

      button {
        width: 45px;
        height: 45px !important;
      }
    }

    div.show-fields-mobile,
    div.split-view-box {
      z-index: 2;
      position: absolute;
      bottom: 14px;
      padding: 12px;
      width: 100%;
    }

    div.show-fields-mobile {
      button {
        width: 100%;
      }

      @media screen and (min-width: 1025px) {
        display: none;
      }
    }

    div.split-view-box {
      display: flex;
      flex-direction: row;
      justify-content: center;

      div.hstack {
        width: 100%;
        button {
          width: 100%;
          font-weight: 600;
          font-size: 1.1rem;
          text-transform: uppercase;
        }
      }

      div.items {
        width: 100%;
      }
    }
  }

  div.sidebar {
    display: none;
    justify-content: space-between;
    flex-direction: column;
    background-color: $dy-grey-primary;
    height: 100%;

    &[class~="full-screen"] {
      display: flex;
      width: 100%;

      div.header {
        background-color: $dy-grey-bg;
        padding: 1.2rem;
        height: 60px;

        button {
          margin: 0;
          padding: 0;

          i {
            color: $dy-grey-text;
          }
        }

        h5 {
          margin-left: auto;
        }
      }

      div.footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 1.4rem 1rem;

        .btn {
          i {
            font-size: 1.4rem;
          }
        }

        .btn.active {
          background: $dy-blue-active;
          border-color: $dy-blue;
        }
      }
    }

    p,
    div.item-value {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    div.header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 75px;

      &.field {
        a.dropdown-item i {
          color: $dy-blue;
          font-size: 14px;
        }

        h5 {
          padding: 0 5px;
        }
      }

      a:not(.go-back),
      div.dropstart {
        margin-left: auto;
      }

      h5 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(#{$min-sidebar-width} - 100px);
      }

      h5,
      i {
        font-size: 20px;
      }

      i {
        color: $dy-green;
      }

      i.add-field,
      i.control,
      a:not(.dropdown-item) i {
        font-size: 20px;
        color: $dy-grey-text;
      }
    }

    div.search-bar {
      padding: 0.75rem;

      div.input-group {
        box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 1);
      }
    }

    div.content {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      div.item-container {
        display: grid;
        grid-template-columns: calc(100% - 35px) 30px;
        grid-template-rows: 100px;
        grid-template-areas: "link dropdown";
        cursor: pointer;

        border-top: 1px solid $dy-grey-bg;
        &:first-child {
          border-top: none;
          border-bottom: 1px solid $dy-grey-bg;
        }

        &:nth-child(2) {
          border-top: none;
        }

        @media (hover) {
          &:hover {
            background: $dy-grey-bg;
          }
        }
      }

      .dropstart {
        grid-area: dropdown;
        align-self: center;
        text-align: center;
      }

      .item {
        grid-area: link;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 12px 10px 10px;

        text-decoration: none;

        div.svg-polygon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
          height: 75px;
        }

        div.info {
          flex-grow: 1;
          max-width: 270px;
          padding: 0.5rem;

          div.main {
            display: flex;
            align-items: center;
            i {
              color: $dy-green;
            }
          }

          h5,
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          h5 {
            font-size: 16px;
          }

          p {
            font-size: 15px;
          }

          div.group-and-area {
            color: $dy-grey-text;
            div.group {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        div.dropstart {
          margin-left: auto;
        }

        @media (hover) {
          &:hover {
            background: $dy-grey-bg;
          }
        }
      }

      div.collapsable-item {
        div.title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          background-color: $dy-grey-secondary;
          border-bottom: 1px solid $dy-grey-bg;
          padding: 0rem 1.5rem;
        }

        div.collapse {
          transition: min-heigth 0.4s linear;

          div.item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-bottom: 1px solid $dy-grey-bg;
            border-top: none;
            color: $dy-grey-text;
            padding: 0.75rem 2rem;
            cursor: default;

            &-label {
              font-size: 14px;
              font-weight: 600;
              color: $dy-blue;
              i {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 2px;
                color: $dy-green;
              }
              svg {
                margin-right: 14px;
              }
            }

            &-value {
              margin-left: 28px;
            }

            &:hover {
              background: transparent;
            }
          }

          div.item-value-alt {
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: flex-start;
            border-bottom: 1px solid $dy-grey-bg;
            color: $dy-grey-text;

            label {
              font-weight: 600;
              color: $dy-blue;
              i {
                display: inline-block;
                width: 15px;
                height: 25px;
                color: $dy-green;
              }
            }

            input + label {
              color: $dy-grey-text;
            }

            div.item-value {
              max-width: 245px;
              margin-left: 5px;
            }
          }

          div.item div.item-value-alt {
            border-bottom: none;
          }

          div.soil-props,
          div.water-props,
          div.plant-props {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 400;

            & > * {
              flex-basis: 100%;
            }

            div.item-value-alt {
              div.item-label {
                font-size: 14px;

                button i {
                  color: $dy-grey-text;
                  font-size: 13px;
                  margin-top: 3px;
                  height: 23px;
                }
              }

              p.small {
                font-size: 13px;
              }
            }
          }

          div.soil-props {
            border-bottom: none;

            div.item-value-alt {
              div.item-label {
                &.sub-label {
                  font-size: 13px;
                }
              }
            }

            div.item-value-alt:not(:first-child) {
              margin-left: 29px;
            }
          }

          div.water-props {
            div.item-value-alt {
              div#sensors {
                div.react-select__control {
                  min-height: 30px;

                  & > * {
                    max-height: 30px;
                  }
                }

                div.react-select__menu {
                  div.react-select__option {
                    padding: 5px;
                  }
                }
              }
            }

            div.progress-bar {
              &.bg-warning {
                background-color: #f29509 !important;
              }

              &.bg-success {
                background-color: $dy-green !important;
              }

              &.bg-danger {
                background-color: $dy-red !important;
              }

              &.bg-info {
                background-color: $dy-blue !important;
              }
            }
          }

          div.plant-props {
            border-bottom: none;

            div.item {
              gap: 1rem;
            }

            div.item-value-alt {
              width: 100%;
              justify-content: space-between;

              .item-value {
                margin-left: 0;
              }
            }

            #cultivatedCulture,
            #cultivatedCultureVariety {
              p {
                max-width: 15ch;
              }
            }
          }
        }

        &:first-child {
          border-top: 1px solid $dy-grey-bg;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    @media screen and (min-width: 1025px) {
      display: flex;
      width: $min-sidebar-width;
      z-index: 2;
      box-shadow: -25px 0px 15px -20px rgba(0, 0, 0, 0.3);

      div.header {
        padding: 0.5rem 1rem;

        i:not(.add-field) {
          margin-right: 5px;
        }

        i,
        h5 {
          font-size: 20px;
        }
      }

      div.content {
        div.item {
          div.info {
            max-width: 280px;
          }
        }

        div.collapsable-item {
          div.item {
            &-value {
              max-width: 280px;
            }
          }

          div.item-value-alt {
            div.item-value {
              max-width: 280px;
            }
          }
        }
      }
    }
  }

  button:not(.suggestion):not(.search):not(.fetch-index) {
    height: 50px;
  }

  .dropdown-item {
    font-weight: 500;
    text-align: start;
    color: $dy-blue;
  }

  @media screen and (min-width: 1025px) {
    padding-right: 1.25rem;
  }
}

div.wrapper.dashboard.mobile {
  div.sidebar[class~="full-screen"] {
    @media screen and (min-width: 680px) {
      div.content {
        div.item {
          div.info {
            max-width: 600px;
          }
        }

        div.collapsable-item {
          div.item {
            &-value {
              max-width: 600px;
            }
          }

          div.item-value-alt {
            div.item-value {
              max-width: 600px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 930px) {
      div.content {
        div.item {
          div.info {
            max-width: 900px;
          }
        }

        div.collapsable-item {
          div.item {
            &-value {
              max-width: 900px;
            }
          }

          div.item-value-alt {
            div.item-value {
              max-width: 900px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  div.wrapper.field {
    div.map-container {
      height: calc(100% - 60px) !important;
    }
  }
}
