$sidebar-width: 265px;
$sidebar-mini-width: 65px;
$topbar-height: 65px;

div.burger-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 48px;
  height: 48px;

  i.burger {
    color: $dy-blue;
    width: 48px;
  }

  @media (hover) {
    cursor: pointer;
  }
}

select.language-selector,
select.language-selector:focus {
  border: none !important;
  background: transparent;
  text-align-last: center;
  color: $dy-grey-text;
}

nav.sidebar {
  // Size
  min-width: 0;
  height: 100dvh;
  // Positioning
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  // Colors
  background-color: $dy-blue;
  color: $dy-green;
  // Transition
  transition: all 300ms ease-in-out;

  header {
    height: $topbar-height;
    padding: 16px;
    display: flex;
    align-items: center;

    a.navbar-brand {
      margin: 0;
      width: 225px;
      height: 40px;
      outline: none;
      overflow: hidden;

      span {
        display: none;
      }
    }

    div.controls {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      select.language-selector,
      select.language-selector:focus {
        color: $dy-grey-primary;
      }

      span {
        color: $dy-grey-light;
        font-size: 14px;
      }
    }
  }

  div.menus {
    // Positioning
    position: relative;
    // Size
    height: calc(100dvh - #{$topbar-height});
    // Overflow
    overflow: hidden;
    overflow-y: auto;

    nav.nav {
      display: flex;
      flex-wrap: wrap;

      div.nav-item {
        display: flex;
        align-items: center;
        flex-basis: 100%;

        padding: 2.5px 0;

        .nav-label,
        .nav-link {
          padding: 0; // clear padding from bootstrap
          height: 50px;
          display: flex;
          align-items: center;
          flex-grow: 1;
          font-size: 16px;
          letter-spacing: 0.25px;
        }

        .nav-label {
          &-text {
            font-weight: 700;
            overflow: hidden;
          }

          &-bullet {
            display: none;
          }
        }

        .nav-link {
          outline: none;
          font-weight: 400;
          color: $dy-grey-light;

          &-label {
            overflow: hidden;
          }

          i {
            font-size: 19px;
          }

          div.decorated-icon-container {
            position: relative;

            span.badge {
              position: absolute;
              font-size: 11px;
              padding: 0.3em 0.4em;
              transform: translate(-42%, -38%);
            }
          }

          .decorated {
            display: none;
          }

          &.active {
            background-color: $dy-green;
          }

          @media (hover) {
            &:hover {
              color: $dy-grey-hover;
            }
          }
        }
      }
    }
  }

  &.collapsed {
    transition: all 300ms ease-in-out;
    transform: translateX(-100%);

    & ~ main {
      margin-left: 0;
    }
  }

  &.expanded {
    width: $sidebar-width;

    & ~ main {
      margin-left: $sidebar-width;

      header.topbar {
        width: calc(100% - #{$sidebar-width});
      }
    }

    div.menus {
      nav.nav {
        div.nav-item {
          .nav-label,
          .nav-link {
            padding: 0 16px;
            justify-content: flex-start;
            // gap: 0.75rem;
          }

          .nav-link {
            i,
            div.decorated-icon-container {
              width: 32px;
            }
          }

          .nav-label {
            &-bullet {
              display: none;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;

      header {
        justify-content: space-between;
      }

      & ~ main {
        header.topbar {
          width: 100%;
        }
      }
    }
  }

  &.mini {
    width: $sidebar-mini-width;

    & ~ main {
      margin-left: $sidebar-mini-width;

      header.topbar {
        width: calc(100% - #{$sidebar-mini-width});
      }
    }

    header {
      padding: 0;
      justify-content: center;

      a.navbar-brand {
        width: 40px;
      }
    }

    div.menus {
      nav.nav {
        div.nav-item {
          .nav-label,
          .nav-link {
            justify-content: center;
          }

          .nav-label {
            &-text {
              display: none;
            }

            &-bullet {
              display: block;
            }
          }

          .nav-link {
            outline: none;

            &-label {
              display: none;
            }
          }
        }
      }
    }
  }
}

main {
  font-weight: 500;
  margin-top: $topbar-height;
  height: calc(100dvh - #{$topbar-height});
  transition: all 300ms ease-in-out;

  header.topbar {
    // Size
    width: 100%;
    height: $topbar-height;
    // Positioning
    position: fixed;
    // Content disposition
    display: flex;
    justify-content: space-between;
    align-items: center;
    // General rules
    top: 0;
    padding: 1rem;
    z-index: 3;
    // Colors
    background-color: $dy-grey-light;
    color: $dy-grey-text;

    a.navbar-brand {
      margin: 0;
      width: 32px;
      height: 32px;

      span {
        display: none;
      }
    }

    div.userinfo {
      // Style
      font-size: 16px;
      font-weight: 500;
      background-color: $dy-grey-bg;
      // General
      border-radius: 50em;
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);

      .username {
        padding-left: 10px;
        display: inline;
      }

      .avatar {
        width: 40px;
        height: 40px;
        border: 4px solid $dy-blue;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        span.badge.rounded-pill {
          position: absolute;
          font-size: 13px;
          padding: 0.3em 0.5em;
        }
      }

      .dropdown-toggle {
        min-width: 11rem !important;
        &::after {
          display: none;
        }

        @media screen and (max-width: 1024px) {
          min-width: auto !important;
        }
      }

      .dropdown-menu {
        margin-left: 1rem;
        margin-top: 0.4rem;
        min-width: 200px;

        p {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
        }
      }

      @media screen and (max-width: 1024px) {
        min-width: auto !important;

        .username {
          display: none;
        }
      }
    }

    select.language-selector {
      display: none;

      @media (min-width: calc(1024px + 0.2px)) {
        display: block;
      }
    }
  }

  div.wrapper {
    // General
    padding: 1rem;

    div.container-fluid {
      min-height: calc(100dvh - 100px);
    }
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0 !important;
  }

  @media screen and (min-width: "2601px") {
    display: flex;
    justify-content: center;
    div.wrapper {
      width: 100%;
      max-width: 2600px !important;
      div.container-fluid {
        height: calc(100dvh - #{$topbar-height} - 2rem);
      }
    }
  }
}
