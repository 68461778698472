.device {
  div.timeseries {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .toolbar {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .period {
        display: flex;
        flex-direction: column;

        #dateTimePicker {
          margin-top: 0.5rem;
        }
      }

      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
        flex-wrap: wrap;

        .period,
        .range {
          display: grid;
          align-items: center;
          column-gap: 0.5rem;
          grid-template-columns: repeat(2, max-content);
        }

        .period:has(#dateTimePicker) {
          grid-template-columns: repeat(3, max-content);

          #dateTimePicker {
            margin-top: 0;
          }
        }
      }
    }
  }

  div.charts {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    gap: 1rem;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    span.last-value-timestamp {
      text-transform: capitalize;
    }
  }

  .horizontal-card.card.data {
    // flex: 1;
    flex-basis: calc(50% - 0.5rem);
    flex-direction: column;

    .horizontal-card-left {
      width: 100%;

      div.text-content:last-child {
        border-bottom: 1px solid $gray-500;
      }
    }

    .horizontal-card-right {
      width: 100%;
    }

    div.chart {
      display: flex;
      div:first-child {
        width: 100% !important;
        canvas {
          width: 100% !important;
        }
      }
    }
  }

  .horizontal-card.device-info {
    flex-direction: column-reverse;

    .horizontal-card-left {
      height: 400px;

      div.map-container {
        padding: 13px;
        padding-top: 0;
      }

      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        width: 400px;

        div.map-container {
          padding-top: 13px;
          padding-right: 0;
        }
      }
    }

    .horizontal-card-right {
      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        width: calc(100% - 400px - 5px);
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        flex-wrap: wrap;
        flex-direction: row;

        & > .text-content {
          flex: 50%;
          justify-content: center;
        }
      }
    }

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
    }
  }
}

.device {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  div.header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .extra {
      grid-area: extra;
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;

      div.info-notes {
        display: flex;
        gap: 1rem;
      }

      div.info-notes {
        flex-direction: column;
      }

      @media (min-width: 768px) {
        flex-direction: row;

        div.info-notes {
          flex-direction: row;
        }
      }

      @media (min-width: 1024px) {
        margin-left: auto;

        div.info-notes {
          flex-direction: row;
        }
      }
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      div.extra {
        flex-shrink: 0;
      }
    }
  }
}

.device {
  &-gallery,
  &-gallery-manager {
    overflow: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-height: calc(100dvh - #{$topbar-height} - 160px);

    .hidden-loading-spinner {
      display: none;
    }

    .fetching-loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
    }

    @media screen and (min-width: 768px) {
      max-height: calc(100dvh - #{$topbar-height} - 150px);
    }
  }

  &-gallery {
    justify-content: center;
    gap: 0.25rem;

    .img-thumbnail {
      width: 100px;
      height: 100px;
      cursor: pointer;
    }

    @media screen and (min-width: 370px) {
      gap: 2.25rem;

      .img-thumbnail {
        width: 150px;
        height: 150px;
      }

      .fetching-loading-spinner {
        width: 150px;
        height: 150px;
      }
    }

    @media screen and (min-width: 500px) {
      gap: 0.5rem;
    }

    @media screen and (min-width: 660px) {
      .img-thumbnail {
        width: 200px;
        height: 200px;
      }

      .fetching-loading-spinner {
        width: 200px;
        height: 200px;
      }
    }

    @media screen and (min-width: 768px) {
      justify-content: start;
    }
  }
}
