.rbt-aux {
  margin-right: 2.5rem !important;
}

/* ----------------- Date-Time Picker ----------------- */

div.date-time-picker.input-group {
  flex-wrap: nowrap;
}

div.date-time-picker-container,
.react-datepicker-wrapper {
  width: 100%;
}

div.react-datepicker__input-container > .form-control.left {
  border-radius: 6px 0 0 6px;
}

div.react-datepicker__input-container > .form-control.right {
  border-radius: 0 6px 6px 0;
}

.react-datepicker__day--in-selecting-range {
  background-color: $dy-blue !important;
  color: $dy-grey-secondary !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $dy-blue-active !important;
  color: $dy-grey-secondary !important;
}

$datepicker__font-family: "Montserrat", sans-serif;
$datepicker__font-size: 12px;
$datepicker__border-color: $gray-500;
$datepicker__border-radius: 6px;
$datepicker__selected-color: $dy-blue;
$datepicker__muted-color: $dy-grey-hover;
$datepicker__text-color: $dy-grey-text;
$datepicker__header-color: $dy-grey-text;
$datepicker__highlighted-color: $dy-grey-hover;
$datepicker__holidays-color: $dy-red;

#dateTimePicker {
  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    .react-datepicker-popper {
      transform: translate(0, 48px) !important;
    }
  }
}

div.date-time-picker {
  input.form-control.widget,
  span.input-group-text {
    border-color: $gray-500;
    height: 38px;
  }
}

div.date-time-picker > .form-control {
  padding: 0 !important;
  border: 0 !important;
}

div.date-time-picker div.rdt.left > .form-control {
  border-radius: 6px 0 0 6px;
}

div.date-time-picker div.rdt.right > .form-control {
  border-radius: 0 6px 6px 0;
}

div.date-time-picker div.rdt.right > .rdtPicker {
  margin-left: -2.1rem;
}

/* ----------------- Sensors Dropdown ----------------- */

/* ----------------- Date-Time Range Filter ----------------- */

.date-time-range-filter {
  display: flex;
  flex-direction: column;
}

/* ----------------- Date-Time Range Filter ----------------- */

/* ----------------- Extension Icons ----------------- */

.extension-icon > svg {
  width: 100px;
  height: 100px;
}

/* ----------------- Extension Icons ----------------- */

/* ----------------- Embla Slider ----------------- */

.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
  gap: 1rem;
}

.embla__slide {
  flex: 0 0 auto;
  min-width: 0;
}

/* ----------------- Embla Slider ----------------- */

/* ----------------- Google map overlays ----------------- */

.gm-style iframe + div {
  border: none !important;
}

div.map-zoom-controls {
  position: relative;
  z-index: 3;
  left: 12px !important;

  height: 90px;
  button {
    width: 45px !important;
    height: 45px !important;
  }

  &.only-child {
    bottom: 0px !important;
  }
}

div.map-layers,
div.map-navigation {
  position: relative;
  z-index: 3;
  left: 12px !important;
}

div.map-layers {
  top: 8px !important;

  div.dropdown-header {
    font-size: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  div.dropdown-item {
    label {
      color: $dy-grey-text;
      font-size: 14px;
    }
  }
}

div.map-navigation {
  bottom: 8px !important;
}

div.map-fit-bounds {
  position: relative;
  z-index: 3;
  top: 14px !important;
  left: 12px !important;

  height: 45px;
  button {
    width: 45px !important;
    height: 45px !important;
  }
}

div.map-fullscreen {
  position: relative;
  z-index: 3;
  right: 12px !important;
  bottom: 6px !important;
}

div.map-layers,
div.map-fullscreen,
div.map-navigation,
div.map-geojson-info {
  box-sizing: border-box;
  height: 45px;

  button.btn,
  button.dropdown-toggle {
    width: 45px !important;
    height: 45px !important;
    color: white;
    border-color: $dy-blue-hover !important;

    &.active,
    &:active,
    &:hover,
    &.show,
    &.show:hover {
      background-color: $dy-blue;
      border-radius: 6px !important;
    }

    @media screen and (min-width: 1025px) {
      &.show,
      &.active,
      &:active {
        background: $dy-blue-active !important;
        border: 1px solid $dy-blue-hover !important;
      }
    }
  }
}

div.map-drawing-manager {
  position: relative;
  z-index: 3;
  bottom: 20px !important;

  div.btn-group {
    height: 45px !important;

    & > button.btn-primary {
      color: white;
      height: 45px !important;
      border: 1px solid $dy-blue-hover !important;

      &:disabled {
        opacity: 1 !important;
        background-color: $dy-blue-disabled !important;
      }

      &.active,
      &:active,
      &:hover,
      &.show,
      &.show:hover {
        background-color: $dy-blue;
      }

      @media screen and (min-width: 1025px) {
        &.show,
        &.active,
        &:active {
          background: $dy-blue-active !important;
          border-color: $dy-blue-hover !important;
        }
      }
    }

    & > button.btn-success {
      color: white;
      height: 45px !important;
      border: 1px solid $dy-blue-hover !important;

      &:disabled {
        opacity: 0.85 !important;
        background-color: $dy-green-disabled !important;
      }

      // &.active,
      // &:active,
      // &:hover,
      // &.show,
      // &.show:hover {
      //   background-color: $dy-blue;
      // }

      // @media screen and (min-width: 1025px) {
      //   &.show,
      //   &.active,
      //   &:active {
      //     background: $dy-blue-active !important;
      //     border-color: $dy-blue-hover !important;
      //   }
      // }
    }
  }
}

div.map-addressbar {
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  div.rbt {
    position: absolute !important;
    top: 50%;
    left: 50%;
    min-width: 45rem;
    transform: translate(-50%, -50%);
    transition: all 0.45s ease-out;

    input.form-control {
      height: 2.813rem;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.8);
    }
  }

  &.bg {
    transition: all 0.45s ease-in;
    min-height: 100%;

    div.rbt {
      max-width: 45rem;
    }
  }

  &.no-bg {
    background-color: transparent;
    min-height: 0;
    max-height: 45px;
    min-width: 100%;
    transition: all 0.45s ease;

    div.rbt {
      top: 12px;
      left: 12px;
      transform: translate(0, 0);
      min-width: calc(100% - 24px);
      max-width: calc(100% - 24px);
      transition: all 0.45s ease-out;

      input.form-control.up {
        opacity: 0.95;
      }
    }
  }
}

div.map-geojson-info {
  z-index: 3;
  position: absolute;
  top: 12px;
  right: 12px;

  div.dropdown-menu.show {
    padding: 0;
    font-size: 14px;
    color: $dy-grey-text;

    display: grid;
    grid-template-columns: auto;

    div.info-block {
      display: flex;
      align-items: baseline;
      padding: 0.4rem;
      border-bottom: 1px solid $gray-500;

      &:first-of-type {
        grid-column: 1 / -1;
        align-items: center;
        border-bottom: 1px solid $gray-500;

        height: 45px;
        padding: 0 0.5rem;

        span#index {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media screen and (min-width: 1200px) {
            max-width: none;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      div.info-block {
        &:first-of-type {
          span#index {
            width: auto;
            max-width: 20ch;
          }
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.info-block {
        &:first-of-type {
          span#index {
            width: 100%;
            max-width: auto;
          }
        }
      }
      grid-template-columns: repeat(2, minmax(20ch, 30ch));
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
      div.info-block {
        &:first-of-type {
          span#index {
            width: auto;
            max-width: 20ch;
          }
        }
      }
    }

    &:has(> :last-child:nth-child(3)),
    &:has(> :last-child:nth-child(5)),
    &:has(> :last-child:nth-child(7)) {
      div.info-block {
        &:last-child {
          border-bottom: none;
        }
      }

      @media screen and (min-width: 1200px) {
        div.info-block {
          &:first-of-type {
            span#index {
              width: 100%;
              max-width: auto;
            }
          }
        }
        grid-template-columns: repeat(2, auto);
      }
    }

    &:has(> :last-child:nth-child(3)) {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        div.info-block:not(:first-child) {
          border-bottom: none;
        }
      }

      @media screen and (min-width: 1200px) {
        div.info-block {
          &:not(:first-child) {
            border-bottom: none;
          }
        }
        grid-template-columns: repeat(2, auto);
      }
    }

    &:has(> :last-child:nth-child(5)),
    &:has(> :last-child:nth-child(7)) {
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        div.info-block {
          &:last-child,
          &:nth-last-child(2) {
            border-bottom: none;
          }
        }
      }

      @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(23.5ch, 30ch));
        div.info-block {
          &:not(:first-child) {
            border-bottom: 1px solid $gray-500;
          }

          &:last-child,
          &:nth-last-child(2) {
            border-bottom: none;
          }
        }
      }
    }

    // No data, only header
    &:has(> :only-child) {
      div.info-block {
        border-bottom: none;
      }
    }
  }
}

div.map-loading {
  position: absolute;
  z-index: 3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);

  .text-primary {
    color: $dy-blue !important;
  }
}

/* ----------------- Google map overlays ----------------- */

/* ----------------- QR code scanner ----------------- */

div.scanner-container > div:last-child > :first-child > svg {
  width: 300px;
  height: 300px;
}

/* ----------------- QR code scanner ----------------- */

/* ----------------- FullCalendar ----------------- */

.wrapper.calendar {
  height: calc(100dvh - 65px);

  .activities {
    height: 100%;
  }

  .calendar-main {
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 421px) {
      height: calc(100dvh - 145px);
    }

    .calendar-tools {
      .left h4 {
        text-transform: capitalize;
      }

      .right {
        .view-mode,
        .activities-filter {
          .btn {
            &::after {
              margin-left: 0.5rem;
            }

            &.void {
              height: 43px;
              padding: 0.25rem 0.6rem;
              border-radius: 6px;
              border: none;
              background-color: transparent;

              &::after {
                display: none;
              }

              &:active,
              &.show {
                background-color: $dy-grey-primary;
              }
            }
          }
        }

        .view-mode {
          input[type="radio"]:checked {
            background-color: #fff;
            border: 1px solid $dy-grey-text;
            border-color: $dy-grey-bg;
            --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23636363%27/%3e%3c/svg%3e");
          }
        }
      }
    }

    .calendar-content {
      display: flex;
      justify-content: center;
      height: 100%;

      table {
        border-collapse: collapse;
        border-style: hidden;
        border-color: $dy-grey-text;

        th {
          border: none;
          padding: 0.25rem;
          font-size: 15px;
          color: $dy-green;
          text-transform: capitalize;

          @media screen and (min-width: map-get($grid-breakpoints, md)) {
            text-align: left;
          }
        }

        td {
          padding: 0.25rem;
          border-color: #d9d9d9 !important;
        }
      }

      .fc {
        width: 100%;

        &-daygrid-day-top {
          flex-direction: row;
          justify-content: center;
          font-weight: 700;
          color: $dy-blue;

          @media screen and (min-width: map-get($grid-breakpoints, md)) {
            justify-content: flex-start;
          }
        }

        &-day-today {
          --fc-today-bg-color: none;

          .fc-daygrid-day-number {
            width: 30px;
            border-radius: 50%;
            background-color: $dy-blue;
            text-align: center;
            color: #fff;
          }
        }

        &-more-link {
          color: $dy-blue;
          background-color: $dy-grey-secondary;
          padding: 3px;
        }

        &-highlight {
          --fc-highlight-color: #f5f5f5;
        }

        &-daygrid-event-harness a {
          padding: 0;
          margin: 0;
        }

        &-daygrid-day-events {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-top: 0.3rem;
        }

        &-daygrid-dot-event:hover {
          cursor: pointer;
          background-color: $dy-grey-secondary;
        }

        &-event-main {
          &:hover {
            cursor: pointer;
          }
        }

        &-popover-body {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }

  .activity-container {
    width: 100%;
    padding: 6px;
    font-size: 12px;
    color: $dy-grey-text;

    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: start;
    justify-content: start;

    .bullet {
      grid-area: bullet;
      justify-self: center;
      margin-right: 3px;
      span {
        margin-top: 6px;
        width: 7px;
        height: 7px;
      }
    }

    .field {
      display: none;
      grid-area: field;
      width: 15ch;
    }

    .info {
      grid-area: info;
      display: grid;
      align-items: start;
      gap: 0px 0.25rem;

      .title {
        grid-area: title;
        width: 100%;
      }

      .time {
        grid-area: time;
      }
    }

    .title,
    .field {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // Both view
  .fc-event:not(:has(.fc-event-main)) {
    .activity-container {
      .info {
        grid-template-columns: auto;
        grid-template-rows: max-content max-content;
        grid-template-areas:
          "title"
          "time";
      }

      @media screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: 670px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3px;

        .title,
        .time,
        .field {
          font-size: 10px;
        }

        .title,
        .field {
          width: 5ch;
        }
      }

      @media screen and (min-width: 670px) and (max-width: map-get($grid-breakpoints, xl)) {
        .title {
          width: 9ch;
        }

        .field {
          width: 8ch;
        }
      }

      @media screen and (min-width: map-get($grid-breakpoints, xxl)) {
        .info {
          grid-template-columns: repeat(2, max-content);
          grid-template-areas: "title time";
        }
      }
    }
  }

  // Day grid view
  .fc-view-harness:has(.fc-dayGridMonth-view) {
    .activity-container {
      grid-template-areas: "bullet info";
    }

    .fc-event:has(.fc-event-main) {
      .activity-container {
        grid-template-rows: max-content;
        grid-template-columns: max-content;
        grid-template-areas: "info";
      }
    }
  }

  // Week view
  .fc-view-harness:not(:has(.fc-dayGridMonth-view)) {
    .field {
      display: block;
    }

    .activity-container {
      grid-template-rows: repeat(2, max-content);
      grid-template-areas:
        "bullet info"
        "bullet field";

      @media screen and (min-width: map-get($grid-breakpoints, xxl)) {
        .info {
          grid-template-columns: repeat(2, max-content);
          grid-template-rows: max-content;
          grid-template-areas: "title time";
        }
      }
    }

    .fc-event:has(.fc-event-main) {
      .activity-container {
        grid-template-columns: max-content;
        grid-template-rows: repeat(2, max-content);
        grid-template-areas:
          "info"
          "field";
      }
    }
  }

  // With long event
  .fc-event:has(.fc-event-main) {
    .activity-container {
      gap: 0.15rem;
      color: $dy-grey-primary;

      .bullet {
        display: none;
      }

      .info {
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: max-content;
        grid-template-areas: "title time";
      }

      @media screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: 670px) {
        font-size: 10px;
      }
    }
  }

  // Popover
  .fc-popover-body:has(.fc-event) {
    @media screen and (min-width: map-get($grid-breakpoints, xs)) {
      .activity-container {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        grid-template-areas:
          "bullet info"
          "bullet field";
        align-items: start;
        justify-content: start;
        padding: 6px;

        .title,
        .time,
        .field {
          font-size: 12px;
        }

        .field {
          display: block;
          width: 20ch !important;
        }

        .title {
          width: 100%;
        }

        .info {
          grid-template-columns: repeat(2, max-content);
          grid-template-rows: max-content;
          grid-template-areas: "title time";
        }
      }

      .fc-event:has(.fc-event-main) {
        .activity-container {
          grid-template-rows: repeat(2, max-content);
          grid-template-areas:
            "info"
            "field";
        }
      }
    }
  }
}

/* ----------------- FullCalendar ----------------- */

/* ----------------- Media queries ----------------- */

@media screen and (max-width: map-get($grid-breakpoints, md)) {
  div.position-with-map {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
  div.position-with-map {
    display: flex;
    flex-direction: row;
  }
}

/* ----------------- Media queries ----------------- */
