div.profile {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    div.addresses {
      gap: 1.5rem;
    }
  }

  .card {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

    .card-body {
      .text-content.bt {
        border-top: 1px $dy-grey-bg;
      }

      .text-content.btb {
        border-top: 1px $dy-grey-bg;
        border-top: 1px $dy-grey-bg;
      }
    }
  }
}
