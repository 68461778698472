@font-face {
  font-family: "din-font";
  src: url("../fonts/din-font.woff2") format("woff2"), url("../fonts/din-font.woff") format("woff"),
    url("../fonts/din-font.ttf") format("truetype"), url("../fonts/din-font.svg#din-font") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome xhack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'din-font';
    src: url('../font/din-font.svg?8182605#din-font') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "din-font";
  font-style: normal;
  font-weight: normal;
  // speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  // margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  // margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-icon-add-new-blue:before {
  content: "\e800";
} /* '' */
.icon-icon-add-new-green:before {
  content: "\e801";
} /* '' */
.icon-icon-area-blue:before {
  content: "\e802";
} /* '' */
.icon-icon-coverage-types-blue:before {
  content: "\e803";
} /* '' */
.icon-icon-diametrical-growth-of-the-trunk-blue:before {
  content: "\e804";
} /* '' */
.icon-icon-arrow-bottom-grey:before {
  content: "\e805";
} /* '' */
.icon-icon-arrow-left-blue:before {
  content: "\e806";
} /* '' */
.icon-icon-irrigation-methods-blue:before {
  content: "\e807";
} /* '' */
.icon-icon-arrow-right-blue:before {
  content: "\e808";
} /* '' */
.icon-icon-irrigation-sources-blue:before {
  content: "\e809";
} /* '' */
.icon-icon-leaf-wetness-blue:before {
  content: "\e80a";
} /* '' */
.icon-icon-login-password-green-1:before {
  content: "\e80b";
} /* '' */
.icon-icon-par-blue:before {
  content: "\e80c";
} /* '' */
.icon-icon-battery-0:before {
  content: "\e80d";
} /* '' */
.icon-icon-pressure-surface-blue:before {
  content: "\e80e";
} /* '' */
.icon-icon-battery-40:before {
  content: "\e80f";
} /* '' */
.icon-icon-battery-60:before {
  content: "\e810";
} /* '' */
.icon-icon-battery-80:before {
  content: "\e811";
} /* '' */
.icon-icon-battery-100:before {
  content: "\e812";
} /* '' */
.icon-icon-been-ok:before {
  content: "\e813";
} /* '' */
.icon-icon-calendar:before {
  content: "\e814";
} /* '' */
.icon-icon-soil-temperature-blue:before {
  content: "\e815";
} /* '' */
.icon-icon-surface-wind-direction-blue:before {
  content: "\e816";
} /* '' */
.icon-icon-surface-wind-speed-blue:before {
  content: "\e817";
} /* '' */
.icon-icon-tenant-blue:before {
  content: "\e818";
} /* '' */
.icon-icon-case-kit-white:before {
  content: "\e819";
} /* '' */
.icon-icon-agea-cultures-blue:before {
  content: "\e81a";
} /* '' */
.icon-icon-change-grey:before {
  content: "\e81b";
} /* '' */
.icon-icon-chat-grey:before {
  content: "\e81e";
} /* '' */
.icon-icon-check-grey:before {
  content: "\e820";
} /* '' */
.icon-icon-check-ok-grey:before {
  content: "\e822";
} /* '' */
.icon-icon-copyright-dynamica-blue:before {
  content: "\e824";
} /* '' */
.icon-icon-crops-white:before {
  content: "\e826";
} /* '' */
.icon-icon-dashboard-white:before {
  content: "\e828";
} /* '' */
.icon-icon-device-settings-green:before {
  content: "\e829";
} /* '' */
.icon-icon-devices-grey:before {
  content: "\e82a";
} /* '' */
.icon-icon-download-white:before {
  content: "\e82f";
} /* '' */
.icon-icon-error-blue:before {
  content: "\e830";
} /* '' */
.icon-icon-dynamica-white:before {
  content: "\e831";
} /* '' */
.icon-icon-fields-green:before {
  content: "\e832";
} /* '' */
.icon-icon-filter-white:before {
  content: "\e834";
} /* '' */
.icon-icon-humidity-blue:before {
  content: "\e83a";
} /* '' */
.icon-icon-hamburger-green:before {
  content: "\e83b";
} /* '' */
.icon-icon-air-temperature-blue:before {
  content: "\e83c";
} /* '' */
.icon-icon-language-blue:before {
  content: "\e83d";
} /* '' */
.icon-icon-login-password-green:before {
  content: "\e83e";
} /* '' */
.icon-icon-electrical-conductivity-of-the-soil-blue:before {
  content: "\e83f";
} /* '' */
.icon-icon-field-groups-blue:before {
  content: "\e840";
} /* '' */
.icon-icon-field-types-blue:before {
  content: "\e841";
} /* '' */
.icon-icon-global-solar-radiation-blue:before {
  content: "\e842";
} /* '' */
.icon-icon-granulometries-blue:before {
  content: "\e843";
} /* '' */
.icon-icon-mail-green:before {
  content: "\e844";
} /* '' */
.icon-icon-map-full-screen-blue:before {
  content: "\e845";
} /* '' */
.icon-icon-map-full-screen-green:before {
  content: "\e846";
} /* '' */
.icon-icon-map-zoom-in-blue:before {
  content: "\e847";
} /* '' */
.icon-icon-my-sensors-blue:before {
  content: "\e848";
} /* '' */
.icon-icon-meter-indicator-negative-blue:before {
  content: "\e849";
} /* '' */
.icon-icon-precipitation-blue:before {
  content: "\e84a";
} /* '' */
.icon-icon-meter-indicator-positive-blue:before {
  content: "\e84b";
} /* '' */
.icon-icon-soil-moisture-blue:before {
  content: "\e84c";
} /* '' */
.icon-icon-name-blue:before {
  content: "\e84d";
} /* '' */
.icon-icon-soil-water-potential-blue:before {
  content: "\e84e";
} /* '' */
.icon-icon-name-login-green:before {
  content: "\e84f";
} /* '' */
.icon-icon-not-selection-grey:before {
  content: "\e850";
} /* '' */
.icon-icon-temperature-blue-1:before {
  content: "\e851";
} /* '' */
.icon-icon-print-blue:before {
  content: "\e852";
} /* '' */
.icon-icon-variables-blue:before {
  content: "\e853";
} /* '' */
.icon-icon-water-ph-blue:before {
  content: "\e854";
} /* '' */
.icon-icon-reset-zoom-green:before {
  content: "\e855";
} /* '' */
.icon-icon-agea-varieties-blue:before {
  content: "\e856";
} /* '' */
.icon-icon-air-humidity-blue:before {
  content: "\e857";
} /* '' */
.icon-icon-samples-blue:before {
  content: "\e858";
} /* '' */
.icon-icon-selection-blue:before {
  content: "\e859";
} /* '' */
.icon-icon-simple-down-arrow-blue:before {
  content: "\e85c";
} /* '' */
.icon-icon-tag-blue:before {
  content: "\e85e";
} /* '' */
.icon-icon-simple-left-arrow-white:before {
  content: "\e862";
} /* '' */
.icon-icon-temperature-blue:before {
  content: "\e865";
} /* '' */
.icon-icon-tenants-green:before {
  content: "\e868";
} /* '' */
.icon-icon-time-lapse-green:before {
  content: "\e86a";
} /* '' */
.icon-icon-trash-blue:before {
  content: "\e86c";
} /* '' */
.icon-icon-true-green:before {
  content: "\e86f";
} /* '' */
.icon-icon-users-green:before {
  content: "\e870";
} /* '' */
.icon-icon-view-blue:before {
  content: "\e873";
} /* '' */
.icon-icon-view-lines-green:before {
  content: "\e876";
} /* '' */
.icon-icon-wind:before {
  content: "\e878";
} /* '' */
.icon-icon-zoom-out-blue:before {
  content: "\e879";
} /* '' */
.icon-logo-dynamica:before {
  content: "\e87b";
} /* '' */
.icon-logo-dynamica-loading:before {
  content: "\e87c";
} /* '' */
