div.notifications {
  div.settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div.input-group {
      height: 45px;
      box-shadow: 0px 0px 20px -20px rgba(0, 0, 0, 1);

      .form-control,
      .input-group-text {
        background-color: $dy-grey-light;
        color: $dy-grey-text;
        border: none;
        text-decoration: none;
      }

      .form-control {
        &:focus,
        &:focus-visible {
          border: none !important;
        }
      }

      .input-group-text {
        border-left: 2px solid #e5e5e5;
      }

      @media screen and (min-width: 1366px) {
        width: 50%;
      }
    }

    div.controls {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      div.dropdown {
        button.dropdown-toggle {
          height: 45px;
          width: 100%;

          @media screen and (max-width: 767px) {
            font-weight: 600;
          }

          @media screen and (min-width: 768px) {
            min-width: 135px;
          }
        }

        @media screen and (max-width: 767px) {
          flex-basis: 50%;
        }
      }

      @media screen and (min-width: 1366px) {
        margin-left: auto;
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  a.read-all {
    padding: 0;
    text-decoration: none;
    color: $dy-blue;

    &:hover,
    &:active {
      color: $dy-blue-hover !important;
    }
  }

  div.col-12:last-child {
    max-height: calc(100dvh - #{$topbar-height} - 238px);
    overflow: hidden;
    overflow-y: auto;

    @media screen and (min-width: 768px) {
      max-height: calc(100dvh - #{$topbar-height} - 180px);
    }
  }

  .notification-item {
    display: grid;
    grid-template-columns: 60% auto;
    grid-template-rows: auto;
    gap: 0px 0px;
    grid-template-areas: "content info";
    // border-bottom: 2px solid $dy-grey-bg;
    padding: 1rem 0.2rem;

    border-top: 1px solid $dy-grey-bg;

    @media screen and (min-width: 768px) {
      grid-template-columns: 80% auto;
    }
  }

  .content {
    grid-area: content;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 5px 0px;
    grid-template-areas:
      "title"
      "description";
  }

  .title {
    grid-area: title;

    p {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .description {
    grid-area: description;

    p {
      font-size: 13px;
    }
  }

  .description:not(.clamped) {
    min-height: 80px;
  }

  .description.clamped {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
  }

  .info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-end;
    margin-top: 2px;
    margin-right: 8px;

    p {
      text-transform: capitalize;
      span {
        font-size: 12px;
      }
    }

    @media screen and (min-width: 768px) {
      justify-self: center;
      margin-right: 0;
    }
  }
}
